import { render, staticRenderFns } from "./room_send_lhc_tmdxds.vue?vue&type=template&id=463f8565&scoped=true&"
import script from "./room_send_lhc_tmdxds.vue?vue&type=script&lang=js&"
export * from "./room_send_lhc_tmdxds.vue?vue&type=script&lang=js&"
import style0 from "./room_send_lhc_tmdxds.vue?vue&type=style&index=0&id=463f8565&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "463f8565",
  null
  
)

export default component.exports