<template>
  <div class="list" pullTips="false">
    <div class="title">
      <span class="maintitle">{{ $t('home.consider') }}</span>
      <span class="subtitle">{{ getOdds('eth-fx-fx') }}</span>
    </div>
    <div class="item">
      <div class="item-title">
        <span class="item-title">{{ $t('home.consider') }}</span>
      </div>
      <div class="item-line"></div>
      <div class="item-right">
        <div class="item-row">
          <span v-for="(code, num) in [11, 22, 33, 44, 55, 66]" :key="num" @click="select(0, num, code)"
            :class="[codes[0][num] === code ? 'item-action' : 'item-code']">{{ code }}
          </span>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="title">
      <span class="maintitle">{{ $t('home.anOption') }}</span>
      <span class="subtitle">{{ getOdds('eth-dx-dx') }}</span>
    </div>
    <div class="item">
      <div class="item-title">
        <span class="item-title">{{ $t('home.sameNumber') }}</span>
      </div>
      <div class="item-line"></div>
      <div class="item-right">
        <div class="item-row">
          <span v-for="(code, num) in [11, 22, 33, 44, 55, 66]" :key="num" @click="select(1, num, code)"
            :class="[codes[1][num] === code ? 'item-action' : 'item-code']">{{ code }}
          </span>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="item-title">
        <span class="item-title">{{ $t('home.otherNumber') }}</span>
      </div>
      <div class="item-line"></div>
      <div class="item-right">
        <div class="item-row">
          <span v-for="(code, num) in [1, 2, 3, 4, 5, 6]" :key="num" @click="select(1, num, code)"
            :class="[codes[1][num] === code ? 'item-action' : 'item-code']">{{ code }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list {
  flex: 1;
}

.title {
  margin-top: 30px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.maintitle {
  color: #777777;
  font-size: 30px;
  margin-right: 12px;
}

.subtitle {
  width: 80px;
  text-align: center;
  font-size: 20px;
  padding: 6px 0;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #dddddd;
  background-color: #ffffff;
  color: #888888;
}

.item {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 36px;
  padding-right: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f8fb;
}

.item-title {
  font-size: 28px;
  color: #999999;
  align-items: center;
  width: 90px;
}

.item-line {
  margin-left: 24px;
  margin-right: 24px;
  width: 1px;
  height: 60px;
  background-color: #e4e4e4;
}

.item-space {
  flex: 1;
  height: 16px;
}

.line {
  height: 1px;
  background-color: #e4e4e4;
}

.item-right {
  flex: 1;
}

.item-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.item-code,
.item-action {
  width: 76px;
  height: 76px;
  line-height: 76px;
  border-radius: 38px;
  font-size: 30px;
  text-align: center;
  color: #dc1323;
  background-color: #ffffff;
}

.item-action {
  color: #ffffff;
  background-color: #dc1323;
}
</style>

<script>
export default {
  name: 'RoomSendK3Eth',

  props: {
    value: {
      //   type: Object,
      //   default: [],
    },
    oddsLists: {
      //   type: Object,
      //   default: {},
    }
  },

  data () {
    return {
      codes: [[], []]
    }
  },

  mounted () { },

  methods: {
    getOdds (type) {
      const item = this.oddsLists.find((item) => {
        return item.method == type
      })
      return item ? item.odds : 0
    },

    select (pos, num, code) {
      this.$set(this.codes[pos], num, this.codes[pos][num] !== code ? code : '')
      if (pos === 0) {
        this.$emit('select', 'eth-fx-fx', [this.codes[pos]])
      } else {
        const arr1 = []
        const arr2 = []
        this.codes[pos].forEach((item) => {
          if (item > 10) {
            arr1.push(item)
          } else {
            arr2.push(item)
          }
        })
        this.$emit('select', 'eth-dx-dx', [arr1, arr2])
      }
    }
  }
}
</script>
