<template>
  <div class="message" id="chatMessage" :class="chatContClass">
    <div class="left" v-for="(item, index) in messageLists" :key="index" :class="item.userid === userid ? 'right' : ''">
      <img :src="item.userimg" alt="" class="portrait" v-if="item.userid !== userid" />
      <div class="information">
        <div class="new-item-info">
          <span>{{ item.userphone }}</span>
          <p>{{ $t('chat_room.bet') }}{{ $money(item.lottery.totalMoney) }}</p>
        </div>
      </div>
      <img :src="item.userimg" alt="" class="portrait rightportrait" v-show="item.userid === userid" />
    </div>
    <div v-if="messageLists.length >= 1" class="Wish">
      {{ $t('chat_room.getPrize') }}
    </div>
  </div>
</template>
<script>
import * as RongIMLib from '@rongcloud/imlib-v4'
import { historyMessage } from '@/api/message.js'
import axios from 'axios'
import qs from 'qs'
import sha1 from 'js-sha1'
import { methodCn } from '@/utils/unique'
// import { getLotteryInfo } from '@/api/lottery'
export default {
  name: '',
  components: {},
  props: {
    lotteryType: {
      type: Object,
      required: true,
    },

    userid: {
      type: [Number, String],
      //   required: true
    },
    gameType: {
      type: String,
    },
  },
  data() {
    return {
      // 保存会话列表数据
      list: [],
      IM: null,
      messageLists: [],
      Message: {},
      userInfos: window.localStorage.userInfo
        ? JSON.parse(window.localStorage.userInfo)
        : {},
      userToken: window.localStorage.userToken,
      chatContStyle: {},
      chatContClass: '',
    }
  },
  mounted() { },
  created() {
    if (this.gameType === 'all') {
      this.chatContStyle = {
        height: 'calc(100% - 450px)',
        top: '295px',
      }
      this.chatContClass = 'message1'
    } else {
      this.chatContStyle = {
        height: 'calc(100% - 480px)',
        top: '390px',
      }
      this.chatContClass = 'message2'
    }
    // this.userToken || this.getRongyunToken()
    const that = this
    this.userToken = window.localStorage.userToken
    // this.initRong()
    // this.IM.watch({
    //   conversation: (res) => {
    //     var updatedConversationList = res.updatedConversationList

    //     const updateId =
    //       updatedConversationList[0].latestMessage.content.user.id
    //     const targetId = Number(this.targetId)
    //     if (updateId !== targetId && updateId !== 1) {
    //       return
    //     }
    //     this.list.push(updatedConversationList[0].latestMessage)
    //   },
    //   message: function (event) {
    //     var message = event.message
    //     message.content.content
    //       ? that.messageLists.push(JSON.parse(message.content.content))
    //       : that.messageLists.push(message.content)
    //   },
    //   status(event) {
    //   },

    //   chatroom(event) {
    //   },
    // })
    // this.getConnect()
  },

  updated() {
    this.scrollToBottom()
  },

  watch: {
    Message: function (val) {
      this.messageLists.push(val)
    },
  },
  methods: {
    methodCn2(val) {
      return methodCn(val)
    },

    getRongyunToken() {
      const params = {
        userId: this.userInfos.id,
        // userId: `2::${this.userInfos.id}`,
        name: this.userInfos.username,
        portraitUri: this.userInfos.userimg,
      }
      const Nonce = Math.floor(Math.random() * (10000 - 99999) + 99999)
      const Timestamp = Date.parse(new Date())
      const Signature = sha1(`bRZVyHtt7S${Nonce}${Timestamp}`)
      axios({
        url: '/user/getToken.json',
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'App-Key': 'e5t4ouvpe6y7a',
          'Content-Type': 'application/x-www-form-urlencoded',
          Nonce,
          Timestamp,
          Signature,
        },
        timeout: 60000,
        withCredentials: true,
        validateStatus: (status) => status >= 200 && status < 300,
        data: qs.stringify(params),
      })
        .then((r) => {
          if (r.data.code === 200) {
            window.localStorage.userToken = r.data.token
          }
        })
        .catch((e) => {
          console.error(e)
        })
    },
    // 初始化融云
    initRong() {
      this.IM = RongIMLib.init({
        appkey: 'e5t4ouvpe6y7a', // 融云appkey
      })
    },

    // 建立连接
    getConnect() {
      var user = {
        token: this.userToken,
      }
      this.IM.connect(user)
        .then((res) => {
          this.ImWatch()
        })
        .catch((error) => {
          console.error('conact')
        })
    },

    // 用于监听会话列表
    ImWatch() {
      const IM = this.IM
      // 注: im 实例通过 RongIMLib.init 获取(单หนึ่งสถานที่页面仅需初始化一次)
      var chatRoom = IM.ChatRoom.get({
        id: this.lotteryType.roomid,
      })

      chatRoom
        .join({
          count: 20,
        })
        .then((res) => {
          this.messageLists || this.getHistoryMessage()
        })
      //   // 获取会话列表
      //   IM.Conversation.getList(
      //     {
      //       count: 30,
      //       startTime: 0,
      //       order: 0
      //     }
      //   ).then(conversationList => {
      //     
      //   }).catch(error => {
      //     
      //   })
    },

    // 获取历史聊天记录
    async getHistoryMessage() {
      this.$toast(this.$t('common.loading'))
      const {
        data: { ret, data },
      } = await historyMessage({ type: this.lotteryType.type })
      if (ret === 1) {
        for (var obj of data) {
          if (obj !== 'Array') {
            this.messageLists.push(JSON.parse(obj))
          }
        }
        this.$toast.clear()
      }
    },

    scrollToBottom() {
      var div = document.getElementById('chatMessage')
      div.scrollTop = div.scrollHeight
    },
  },
}
</script>

<style lang="less" scoped>
// .message1 {
//   height: calc(100% - 450px);
//   top: 295px;
// }
.message2 {
  height: calc(100% - 480px);
  top: 390px;
}

.message {
  width: 99.5%;
  overflow: auto;
  position: fixed;
  bottom: 75px;
  padding-left: 10px;

  .right {
    display: flex;
    justify-content: flex-end;
  }

  .left {
    display: flex;
    margin-top: 10px;

    /deep/ .van-cell {
      height: 70px;
      padding: 0 24px;
      line-height: 70px;
    }

    /deep/ .van-cell__title {
      font-weight: 700;
    }

    /deep/ .van-cell__value {
      color: #ff0000;
    }

    .portrait {
      width: 100px;
      height: 100px;
      margin-top: 24px;
      margin-right: 20px;
    }

    .rightportrait {
      position: relative;
      top: 0;
      right: 0;
      margin-left: 20px;
    }

    .number {
      font-size: 30px;
      color: #999;
    }

    .numberright {
      display: inline-block;
      width: 500px;
      text-align: right;
      font-size: 30px;
      color: #999;
    }

    .new-item-info {
      margin-top: 36px;
      width: 500px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 26px;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #f6f7f8;
      padding: 24px 14px;
      box-sizing: border-box;

      >span {
        color: #050506;
      }

      >p {
        color: #ff0000;
      }
    }

    .information {
      width: 500px;

      // height: 300px;
      .text {
        padding: 10px 20px;
        font-size: 26px;
        background-color: #fff;
      }
    }

    .text {
      font-size: 34px;
    }
  }

  .Wish {
    padding: 20px 0;
    height: 30px;
    font-family: 'PingFang-Regular';
    font-size: 26px;
    text-align: center;
    line-height: 30px;
  }
}
</style>
