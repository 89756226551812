<template>
  <div class="list" pullTips="false">
    <div class="item">
      <div class="item-right">
        <div class="item-row">
          <div v-for="(code, num) in [$t('common.resources'), $t('common.faint'), $t('common.odd'), $t('common.even')]"
            :key="num" v-show="getOdds('hz-hz-dxds') > 0" @click="select(0, num, code)">
            <span :class="[codes[0][num] === code ? 'item-action' : 'item-code']">{{ code }}</span>
            <div class="item-odds">{{ getOdds('hz-hz-dxds') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="item">
      <div class="item-right">
        <div class="item-row">
          <div v-for="(code, num) in ['3', '4', '5', '6']" :key="num" v-show="getOdds('hz-hz-' + code) > 0"
            @click="select(1, num, code)">
            <div :class="[codes[1][num] === code ? 'item-action' : 'item-code']">
              {{ code }}
            </div>
            <div class="item-odds">{{ getOdds('hz-hz-' + code) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="item-right">
        <div class="item-row">
          <div v-for="(code, num) in ['7', '8', '9', '10']" :key="num" v-show="getOdds('hz-hz-' + code) > 0"
            @click="select(1, num + 4, code)">
            <div :class="[
              codes[1][num + 4] === code ? 'item-action' : 'item-code',
            ]">
              {{ code }}
            </div>
            <div class="item-odds">{{ getOdds('hz-hz-' + code) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="item-right">
        <div class="item-row">
          <div v-for="(code, num) in ['11', '12', '13', '14']" :key="num" v-show="getOdds('hz-hz-' + code) > 0"
            @click="select(1, num + 8, code)">
            <div :class="[
              codes[1][num + 8] === code ? 'item-action' : 'item-code',
            ]">
              {{ code }}
            </div>
            <div class="item-odds">{{ getOdds('hz-hz-' + code) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="item-right">
        <div class="item-row">
          <div v-for="(code, num) in ['15', '16', '17', '18']" :key="num" v-show="getOdds('hz-hz-' + code) > 0"
            @click="select(1, num + 12, code)">
            <div :class="[
              codes[1][num + 12] === code ? 'item-action' : 'item-code',
            ]">
              {{ code }}
            </div>
            <div class="item-odds">{{ getOdds('hz-hz-' + code) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list {
  flex: 1;
}

.item {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 36px;
  padding-right: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f8fb;
}

.line {
  height: 1px;
  background-color: #e4e4e4;
}

.item-right {
  flex: 1;
}

.item-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.item-row div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-code,
.item-action {
  width: 90px;
  height: 90px;
  line-height: 90px;
  border-radius: 45px;
  font-size: 34px;
  text-align: center;
  color: #dc1323;
  background-color: #ffffff;
}

.item-action {
  color: #ffffff;
  background-color: #dc1323;
}

.item-odds {
  width: 90px;
  margin-top: 8px;
  text-align: center;
  font-size: 20px;
  padding: 4px 0;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #dddddd;
  background-color: #ffffff;
  color: #888888;
}
</style>

<script>
export default {
  name: 'RoomSendK3Hz',

  props: {
    value: {
      //   type: Object,
      //   default: [],
    },
    oddsLists: {
      //   type: Object,
      //   default: {},
    }
  },

  data () {
    return {
      codes: [[], []]
    }
  },

  mounted () { },

  methods: {
    getOdds (type) {
      const item = this.oddsLists.find((item) => {
        return item.method == type
      })
      return item ? item.odds : 0
    },

    select (pos, num, code) {
      this.$set(this.codes[pos], num, this.codes[pos][num] !== code ? code : '')
      if (pos == 0) {
        this.$emit('select', 'hz-hz-dxds', [this.codes[pos]])
      } else {
        this.$emit('select', 'hz-hz-' + code, [this.codes[pos][num]])
      }
    }
  }
}
</script>
