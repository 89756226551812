<template>
  <div class="app">
    <div class="cont">
      <div class="title">
        <img class="title-bc" src="@/assets/images/room/senlin/bottom.png" alt="" />
        <img class="title-text" src="@/assets/images/room/senlin/smwh-title.png" />
        <img class="title-colse" src="@/assets/images/room/senlin/close.png" @click="clickClose" />
      </div>
      <div class="select-qiuqiu">
        <div class="color-box">
          <div v-for="(item, index) in colorList" :key="index" class="color-button" :class="[`color-${item.key}`]"
            @click="handleSelectColor(item.key)">
            <img class="color-button-img" :src="require(`@/assets/images/room/senlin/${item.img}.png`)" />
          </div>
        </div>
        <div class="qiuqiu-box">
          <img class="qiquiu" v-for="(item, index) in qiuqiuList" :key="index"
            :src="require(`@/assets/images/room/senlin/${item.key}.png`)" @click="handleSelectQiuQiu(item.key)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoomSend2',
  components: {},
  props: {
    hall_methods: {
      type: Object,
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      // title: '加载中',
      title: 'Loading',
      colorList: [
        {
          key: 'green',
          value: 'chọn màu xanh lá cây',
          img: 'xiongmao',
        },
        {
          key: 'violet',
          value: 'chọn màu tím',
          img: 'long',
        },
        {
          key: 'red',
          value: 'chọn màu đỏ',
          img: 'laohu',
        },
      ],
      qiuqiuList: [
        {
          key: '0',
        },
        {
          key: '1',
        },
        {
          key: '2',
        },
        {
          key: '3',
        },
        {
          key: '4',
        },
        {
          key: '5',
        },
        {
          key: '6',
        },
        {
          key: '7',
        },
        {
          key: '8',
        },
        {
          key: '9',
        },
      ],
    }
  },

  mounted() { },

  methods: {
    /**
     * 选择方块的颜色
     */
    handleSelectColor(color) {
      this.$emit('clickSend', {
        selectType: 'color',
        key: color,
      })
    },

    /**
     * 选择球球
     */
    handleSelectQiuQiu(val) {
      this.$emit('clickSend', {
        selectType: 'qiuqiu',
        key: val,
      })
    },

    /**
     * 关闭
     */
    clickClose() {
      this.$emit('clickClose')
    },

    /**
     * 选择玩法
     */
    clickSelect() {
      this.showMethod = !this.showMethod
    },

    /**
     * 选择玩法
     */
    methodClick(key1, key2) {
      this.showMethod = false
      this.$refs.myTabbar.setCurrentItem('tabName_' + (key1 * 3 + key2))
    },

    /**
     * Đặt cược
     */
    clickSend() {
      let odds = ''
      this.each(this.oddsLists, (index, item) => {
        odds += odds ? '、' : ''
        odds += this.methodCn(item.method) + '（' + item.odds + '）'
      })
      //
      let number = 0
      let numberInfo = ''
      let oneOrderMoney = 0
      const oneMethodMoney = {}
      const selectCodeString = {}
      const codeStringType = this.rightExists(this.type, 'ssc') ? this.type : ''
      this.each(this.selectCodes, (method, item) => {
        const count = this.codeNumber(method, item)
        if (count > 0) {
          number += count
          numberInfo += numberInfo ? '、' : ''
          numberInfo += this.methodCn(method) + ':' + count + 'Ghi chú'
          oneOrderMoney += this.codeOneOrderMoney(method, item)
          oneMethodMoney[method] = this.codeOneOrderMoney(method, item)
          selectCodeString[method] = this.codeString(
            method,
            item,
            codeStringType
          )
        }
      })
      //
      if (number <= 0) {
        this.$toast(this.$t('home.pleaseSelectNumber'))
        return
      }
      //
      this.$emit('clickSend', {
        odds: odds, // tỷ lệ cược
        number: number, // cược số
        numberInfo: numberInfo, // 注数说明
        oneOrderMoney: oneOrderMoney, // 1元最高中奖金额
        oneMethodMoney: oneMethodMoney, // 1元最高中奖金额（组）
        selectCodeString: selectCodeString, // 选择的号码组
      })
    },

    /**
     * 清除选择
     * @param method
     */
    codeClean(method) {
      this.selectCodes[method] = ''
    },
  },
}
</script>
<style scoped>
.app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: space-between;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.7);
}

.cont {
  position: absolute;
  left: 0;
  bottom: 0;
}

.title {
  width: 750px;
  height: 80px;
  max-height: 380px;
  /* background-color: #fdce45; */
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
}

.title-bc {
  position: absolute;
  width: 750px;
  height: 80px;
}

.title-text {
  position: absolute;
  width: 207px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title-colse {
  position: absolute;
  width: 130px;
  height: 58px;
  right: 10px;
  top: 10px;
}

/* .title-text {
  font-size: 48px;
  color: #ff0000;
} */
.select-qiuqiu {
  background-color: #fff;
}

.clean-button {
  color: #e30000;
  background-color: #f5c4c4;
  font-size: 30px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 5px;
  right: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #e30000;
  border-radius: 6px;
  text-align: center;
  line-height: 70px;
}

.color-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.color-button {
  /* width: 200px;
  height: 80px; */
  width: 222px;
  height: 122px;
  border-radius: 8px;
  margin: 36px 0 32px;
}

.color-button-img {
  width: 222px;
  height: 122px;
}

.color-text {
  font-size: 36px;
  color: #fff;
  line-height: 80px;
  text-align: center;
}

.color-green {
  background-color: #13cc00;
  margin-left: 26px;
}

.color-violet {
  background-color: #b400ff;
}

.color-red {
  background-color: #ff0000;
  margin-right: 26px;
}

.qiuqiu-box {
  width: 638px;
  margin: 0 45px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: 100px;
  /* border-style: solid;
  border-width: 1px;
  border-color: #000; */
}

.qiquiu {
  margin: 11px;
  width: 105px;
  height: 105px;
}
</style>
