<template>
  <div class="list" pullTips="false">
    <div v-for="pos in [0]" :key="pos">
      <div class="item">
        <div class="item-right">
          <div class="item-row">
            <div v-for="(code, num) in [$t('home.mouse'), $t('home.cow'), $t('home.tiger')]" :key="num"
              @click="select(pos, num, code)" :class="[codes[pos][num] === code ? 'item-action' : 'item-code']">
              <span :class="[
                codes[pos][num] === code
                  ? 'item-code-action'
                  : 'item-code-code',
              ]">{{ code }}</span>
              <span :class="[
                codes[pos][num] === code
                  ? 'item-num-action'
                  : 'item-num-code',
              ]">{{ code | codeNum }}</span>
            </div>
          </div>
          <div class="item-space"></div>
          <div class="item-row">
            <div v-for="(code, num) in [$t('home.rabbit'), $t('home.babyRabbit'), $t('home.snake')]" :key="num"
              @click="select(pos, num + 3, code)" :class="[
                codes[pos][num + 3] === code ? 'item-action' : 'item-code',
              ]">
              <span :class="[
                codes[pos][num + 3] === code
                  ? 'item-code-action'
                  : 'item-code-code',
              ]">{{ code }}</span>
              <span :class="[
                codes[pos][num + 3] === code
                  ? 'item-num-action'
                  : 'item-num-code',
              ]">{{ code | codeNum }}</span>
            </div>
          </div>
          <div class="item-space"></div>
          <div class="item-row">
            <div v-for="(code, num) in [$t('home.horse'), $t('home.sheep'), $t('home.monkey')]" :key="num"
              @click="select(pos, num + 6, code)" :class="[
                codes[pos][num + 6] === code ? 'item-action' : 'item-code',
              ]">
              <span :class="[
                codes[pos][num + 6] === code
                  ? 'item-code-action'
                  : 'item-code-code',
              ]">{{ code }}</span>
              <span :class="[
                codes[pos][num + 6] === code
                  ? 'item-num-action'
                  : 'item-num-code',
              ]">{{ code | codeNum }}</span>
            </div>
          </div>
          <div class="item-space"></div>
          <div class="item-row">
            <div v-for="(code, num) in [$t('home.chicken'), $t('home.dog'), $t('home.pig')]" :key="num"
              @click="select(pos, num + 9, code)" :class="[
                codes[pos][num + 9] === code ? 'item-action' : 'item-code',
              ]">
              <span :class="[
                codes[pos][num + 9] === code
                  ? 'item-code-action'
                  : 'item-code-code',
              ]">{{ code }}</span>
              <span :class="[
                codes[pos][num + 9] === code
                  ? 'item-num-action'
                  : 'item-num-code',
              ]">{{ code | codeNum }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list {
  flex: 1;
}

.item {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 36px;
  padding-right: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f8fb;
}

.item-space {
  flex: 1;
  height: 20px;
}

.item-right {
  flex: 1;
}

.item-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.item-code,
.item-action {
  /* width: 142px; */
  width: 170px;
  height: 108px;
  border-radius: 12px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-action {
  background-color: #dc1323;
}

.item-code-action,
.item-code-code {
  color: #dc1323;
  font-size: 34px;
  padding-top: 12px;
}

.item-code-action {
  color: #ffffff;
}

.item-num-action,
.item-num-code {
  color: #777777;
  font-size: 20px;
  padding-top: 12px;
}

.item-num-action {
  color: #ffffff;
}
</style>

<script>
import i18n from "@/lang"
const { t } = i18n;
export default {
  name: 'RoomSendLhcZx',

  props: {
    value: {
      //   type: Object,
      //   default: [],
    },
    maxnum: {
      default: 0,
    },
  },

  computed: {
    codeNum(code) {
      switch (code) {
        case t('home.mouse'):
          return '02 14 26 38'
        case t('home.cow'):
          return '01 13 25 37 49'

        case t('home.tiger'):
          return '12 24 36 48'

        case t('home.rabbit'):
          return '11 23 35 47'

        case t('home.babyRabbit'):
          return '10 22 34 46'

        case 'con rắn':
          return '09 21 33 45'

        case 'ngựa':
          return '08 20 32 44'

        case 'con cừu':
          return '07 19 31 43'

        case 'con khỉ':
          return '06 18 30 42'

        case 'gà con':
          return '05 17 29 41'

        case 'chú chó':
          return '04 16 28 40'

        case 'lợn':
          return '03 15 27 39'
      }
    },
  },

  data() {
    return {
      codes: [[]],
    }
  },

  mounted() { },

  watch: {
    value(val) {
      if (val === '') {
        const newValue = []
        for (let i = 0; i < this.codes.length; i++) {
          newValue.push([])
        }
        this.codes = newValue
        this.$emit('input', this.codes)
      }
    },
  },

  methods: {
    select(pos, num, code) {
      this.$set(this.codes[pos], num, this.codes[pos][num] !== code ? code : '')
      this.$emit('input', this.codes)
    },
  },
}
</script>
