import { render, staticRenderFns } from "./room_send_sd_qezhix.vue?vue&type=template&id=5ca5b381&scoped=true&"
import script from "./room_send_sd_qezhix.vue?vue&type=script&lang=js&"
export * from "./room_send_sd_qezhix.vue?vue&type=script&lang=js&"
import style0 from "./room_send_sd_qezhix.vue?vue&type=style&index=0&id=5ca5b381&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ca5b381",
  null
  
)

export default component.exports