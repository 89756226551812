<template>
  <div class="list">
    <div class="list" v-for="pos in [0]" :key="pos">
      <span class="title">{{ $t('home.selectNumbersCreateNote') }}</span>
      <div class="item">
        <div class="item-title">
          <span class="item-title">{{ $t('home.top3') }}</span>
          <span class="item-title">{{ $t('home.groupOfSix') }}</span>
        </div>
        <div class="item-line"></div>
        <div class="item-right">
          <div class="item-row">
            <span v-for="(code, num) in [0, 1, 2, 3, 4]" :key="num" @click="select(pos, num, code)"
              :class="[codes[pos][num] === code ? 'item-action' : 'item-code']">{{ code }}
            </span>
          </div>
          <div class="item-space"></div>
          <div class="item-row">
            <span v-for="(code, num) in [5, 6, 7, 8, 9]" :key="num" @click="select(pos, num + 5, code)" :class="[
              codes[pos][num + 5] === code ? 'item-action' : 'item-code',
            ]">{{ code }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list {
  flex: 1;
  padding-top: 50px;
}

.title {
  color: #888888;
  font-size: 26px;
  margin-bottom: 20px;
  text-align: center;
  display: block;
}

.item {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 36px;
  padding-right: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f8fb;
}

.item-title {
  font-size: 28px;
  color: #999999;
}

.item-line {
  margin-left: 36px;
  margin-right: 36px;
  width: 1px;
  height: 60px;
  background-color: #e4e4e4;
}

.item-space {
  flex: 1;
  height: 16px;
}

.item-right {
  flex: 1;
}

.item-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.item-code,
.item-action {
  width: 76px;
  height: 76px;
  line-height: 76px;
  border-radius: 38px;
  font-size: 30px;
  text-align: center;
  color: #dc1323;
  background-color: #ffffff;
}

.item-action {
  color: #ffffff;
  background-color: #dc1323;
}
</style>

<script>
export default {
  name: 'RoomSendSscQszl',

  props: {
    value: {},
    maxnum: {
      default: 0,
    },
  },

  computed: {},

  data() {
    return {
      codes: [[]],
    }
  },

  mounted() { },

  watch: {
    value(val) {
      if (val === '') {
        const newValue = []
        for (let i = 0; i < this.codes.length; i++) {
          newValue.push([])
        }
        this.codes = newValue
        this.$emit('input', this.codes)
      }
    },
  },

  methods: {
    select(pos, num, code) {
      this.$set(this.codes[pos], num, this.codes[pos][num] !== code ? code : '')
      this.$emit('input', this.codes)
    },
  },
}
</script>
