var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list"},_vm._l(([0]),function(pos){return _c('div',{key:pos,staticClass:"list"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-right"},[_c('div',{staticClass:"item-row"},_vm._l(([_vm.$t('home.red'), _vm.$t('home.lightBlue'), _vm.$t('home.green')]),function(code,num){return _c('div',{key:num,class:[_vm.codes[pos][num] === code ? 'item-action' : 'item-code'],on:{"click":function($event){return _vm.select(pos, num, code)}}},[_c('span',{class:[
                _vm.codes[pos][num] === code
                  ? 'item-code-action'
                  : 'item-code-code' ],style:({
  color: _vm.codes[pos][num] === code ? '#ffffff' : _vm.codeColor(code),
})},[_vm._v(_vm._s(code)+"คลื่น")]),_c('span',{class:[
                _vm.codes[pos][num] === code
                  ? 'item-num-action'
                  : 'item-num-code' ]},[_vm._v(_vm._s(_vm._f("codeNum")(code)))])])}),0)])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }