<template>
  <div class="app">
    <div class="top" pullTips="false">
      <div class="top-item" v-for="(item, method) in selectCodes" :key="method" v-show="codeMeet(method, item)">
        <span class="top-title">{{ methodCnEvent(method) }}:</span>
        <span class="top-code">{{ codeString(method, item, type) }}</span>
        <van-icon name="clear" class="top-close" @click="codeClean(method)" />
      </div>
    </div>

    <div class="bottom">
      <div class="bar">
        <span class="bar-left" @click="clickClose"> {{ $t('common.cancel') }}</span>
        <div class="bar-title" @click="clickSelect">
          <span class="bar-title-desc">{{ $t('home.playStyle') }}</span>
          <div class="bar-title-box">
            <span class="bar-title-text">{{ title }}</span>
            <van-icon name="arrow-down" size="24" />
          </div>
        </div>
        <span class="bar-right" @click="clickSend">{{ $t('home.placeABet') }}</span>
      </div>
      <van-tabs ref="myTabbar" class="tabbar" v-model="active" @change="handleTabsChange">

        <van-tab v-for="(item, key) in oddsTrees.length > 0 ? oddsTrees : oddsLists" :key="key"
          :tabName="'tabName_' + key" :title="methodCnEvent(item.method)">

          <div v-if="type === 'cqssc' ||
            type === 'xjssc' ||
            type === 'tjssc' ||
            type === 'sfssc' ||
            type === 'txffc'
            " class="tabbar-item">

            <room-send-ssc-dwd v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-if="item.method === 'dwd'"></room-send-ssc-dwd>
            <!-- <div v-else-if="item.method === 'dxds'">
              123
            </div> -->
            <room-send-ssc-dxds v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'dxds'"></room-send-ssc-dxds>
            <room-send-ssc-zh v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'zh'"></room-send-ssc-zh>
            <room-send-ssc-qszs v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'qszs'"></room-send-ssc-qszs>
            <room-send-ssc-qszl v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'qszl'"></room-send-ssc-qszl>
            <room-send-ssc-hszs v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'hszs'"></room-send-ssc-hszs>
            <room-send-ssc-hszl v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'hszl'"></room-send-ssc-hszl>
            <room-send-ssc-lh v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'lh'"></room-send-ssc-lh>
            <room-send-ssc-qedxds v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'qedxds'"></room-send-ssc-qedxds>
          </div>
          <div v-else-if="type === 'bjsc' || type === 'xyft' || type === 'sfpk10'" class="tabbar-item">
            <room-send-pk10-dwd v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-if="item.method === 'dwd'"></room-send-pk10-dwd>
            <room-send-pk10-dxds v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'dxds'"></room-send-pk10-dxds>
            <room-send-pk10-gyh v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'gyh'"></room-send-pk10-gyh>
            <room-send-pk10-lh v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'lh'"></room-send-pk10-lh>
          </div>
          <div v-else-if="type === 'pcdd' || type === 'jnd28' || type === 'xy28'" class="tabbar-item">
            <room-send-pcdd-dwd v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-if="item.method === 'dwd'"></room-send-pcdd-dwd>
            <room-send-pcdd-dxds v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'dxds'"></room-send-pcdd-dxds>
            <room-send-pcdd-zh v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'zh'"></room-send-pcdd-zh>
            <room-send-pcdd-bs v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'bs'"></room-send-pcdd-bs>
            <room-send-pcdd-bz v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'bz'"></room-send-pcdd-bz>
          </div>
          <div v-else-if="type === 'hk6hc' || type === 'js6hc'" class="tabbar-item">
            <room-send-lhc-tm v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-if="item.method === 'tm'"></room-send-lhc-tm>
            <room-send-lhc-zm v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'zm'"></room-send-lhc-zm>
            <room-send-lhc-tmdxds v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'tmdxds'"></room-send-lhc-tmdxds>
            <room-send-lhc-tmhsdxds v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'tmhsdxds'"></room-send-lhc-tmhsdxds>
            <room-send-lhc-tmwsdx v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'tmwsdx'"></room-send-lhc-tmwsdx>
            <room-send-lhc-tmdxdsfh v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'tmdxdsfh'"></room-send-lhc-tmdxdsfh>
            <room-send-lhc-tx v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'tx'"></room-send-lhc-tx>
            <room-send-lhc-zx v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'zx'"></room-send-lhc-zx>
            <room-send-lhc-sqz v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'sqz'"></room-send-lhc-sqz>
            <room-send-lhc-eqz v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'eqz'"></room-send-lhc-eqz>
            <room-send-lhc-tmsb v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'tmsb'"></room-send-lhc-tmsb>
          </div>
          <div v-else-if="type === 'jx11x5' || type === 'sd11x5' || type === 'sf11x5'
            " class="tabbar-item">
            <room-send-x5-dwd v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-if="item.method === 'dwd'"></room-send-x5-dwd>
            <room-send-x5-rx1z1 v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'rx1z1'"></room-send-x5-rx1z1>
            <room-send-x5-rx2z2 v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'rx2z2'"></room-send-x5-rx2z2>
            <room-send-x5-rx3z3 v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'rx3z3'"></room-send-x5-rx3z3>
            <room-send-x5-rx4z4 v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'rx4z4'"></room-send-x5-rx4z4>
            <room-send-x5-rx5z5 v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'rx5z5'"></room-send-x5-rx5z5>
            <room-send-x5-rx6z5 v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'rx6z5'"></room-send-x5-rx6z5>
            <room-send-x5-rx7z5 v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'rx7z5'"></room-send-x5-rx7z5>
            <room-send-x5-rx8z5 v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'rx8z5'"></room-send-x5-rx8z5>
            <room-send-x5-qszhix v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'qszhix'"></room-send-x5-qszhix>
            <room-send-x5-qszux v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'qszux'"></room-send-x5-qszux>
            <room-send-x5-hszhix v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'hszhix'"></room-send-x5-hszhix>
            <room-send-x5-hszux v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'hszux'"></room-send-x5-hszux>
            <room-send-x5-qezhix v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'qezhix'"></room-send-x5-qezhix>
            <room-send-x5-qezux v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'qezux'"></room-send-x5-qezux>
            <room-send-x5-hezhix v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'hezhix'"></room-send-x5-hezhix>
            <room-send-x5-hezux v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'hezux'"></room-send-x5-hezux>
          </div>
          <div v-else-if="type === 'fc3d' || type === 'sf3d'" class="tabbar-item">
            <room-send-sd-dwd v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-if="item.method === 'dwd'"></room-send-sd-dwd>
            <room-send-sd-hedxds v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'hedxds'"></room-send-sd-hedxds>
            <room-send-sd-zxzs v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'zxzs'"></room-send-sd-zxzs>
            <room-send-sd-zxzl v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'zxzl'"></room-send-sd-zxzl>
            <room-send-sd-qezhix v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'qezhix'"></room-send-sd-qezhix>
            <room-send-sd-qezux v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'qezux'"></room-send-sd-qezux>
            <room-send-sd-hezhix v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'hezhix'"></room-send-sd-hezhix>
            <room-send-sd-hezux v-model="selectCodes[item.method]" :maxnum="item.maxnum"
              v-else-if="item.method === 'hezux'"></room-send-sd-hezux>
          </div>
          <div v-else-if="type === 'ffk3'" class="tabbar-item">
            <room-send-k3-hz v-if="item.method === 'hz'" :oddsLists="oddsLists" @select="(k, v) => {
              $set(selectCodes, k, v)
            }
              "></room-send-k3-hz>
            <room-send-k3-sth v-else-if="item.method === 'sth'" :oddsLists="oddsLists" @select="(k, v) => {
              $set(selectCodes, k, v)
            }
              "></room-send-k3-sth>
            <room-send-k3-sbth v-else-if="item.method === 'sbth'" :oddsLists="oddsLists" @select="(k, v) => {
              $set(selectCodes, k, v)
            }
              "></room-send-k3-sbth>
            <room-send-k3-lhao v-else-if="item.method === 'lhao'" :oddsLists="oddsLists" @select="(k, v) => {
              $set(selectCodes, k, v)
            }
              "></room-send-k3-lhao>
            <room-send-k3-eth v-else-if="item.method === 'eth'" :oddsLists="oddsLists" @select="(k, v) => {
              $set(selectCodes, k, v)
            }
              "></room-send-k3-eth>
            <room-send-k3-ebth v-else-if="item.method === 'ebth'" :oddsLists="oddsLists" @select="(k, v) => {
              $set(selectCodes, k, v)
            }
              "></room-send-k3-ebth>
          </div>
        </van-tab>
      </van-tabs>
      <div v-if="showMethod" class="method-list">
        <div v-for="(items, key1) in splitNumEvent(
          oddsTrees.length > 0 ? oddsTrees : oddsLists,
          3
        )" :key="key1" class="method-list-item">
          <div v-for="(item, key2) in items" :key="key2" class="method-list-box" @click="methodClick(key1, key2)">
            <!-- <marquee
              class="method-list-title"
              :content="methodCnEvent(item.method)"
            ></marquee> -->
            <van-notice-bar class="method-list-title" :text="methodCnEvent(item.method)" />
            <span v-if="item.odds" class="method-list-odds">{{ $t('home.oddsTwo') }} ：{{ item.odds }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
}

.top {
  width: 100%;
  max-height: 380px;
}

.top-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  padding-left: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e8e8e8;
}

.top-title {
  font-size: 24px;
  color: #888888;
  margin-right: 10px;
}

.top-code {
  flex: 1;
  font-size: 24px;
  color: #242424;
}

.top-close {
  margin-left: 10px;
  width: 90px;
  height: 70px;
  line-height: 70px;
  color: #dc1323;
  font-size: 44px;
  content: 'tb-round-close-fill';
}

.bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.bar {
  flex: 1;
  height: 86px;
  padding-left: 18px;
  padding-right: 18px;
  background-color: #fdce45;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bar-left,
.bar-right {
  width: 110px;
  height: 66px;
  line-height: 66px;
  font-size: 26px;
  text-align: center;
  border-radius: 8px;
  color: #ff5451;
  background-color: #ffefef;
  border-style: solid;
  border-width: 1px;
  border-color: #e9a4a4;
}

.bar-right {
  width: auto;
  color: #ffffff;
  background-color: #ff5451;
  border-color: #ff5451;
}

.bar-title {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bar-title-desc {
  color: #242424;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  margin-right: 15px;
}

.bar-title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 10px;
  border-radius: 6px;
  border-width: 1px;
  border-color: #ffffff;
  border-style: solid;
}

.bar-title-text {
  color: #242424;
  text-align: center;
  font-size: 28px;
}

.bar-title-icon {
  width: 38px;
  height: 58px;
  color: #242424;
  font-size: 24px;
  content: 'tb-triangle-down-fill';
}

.tabbar {
  flex: 1;
  height: 640px;
  font-size: 26px;
  background-color: #f4f8fb;
  tabtype: slidingTop;
  tabheight: 78px;
  indicatorstyle: 1;
  indicatorwidth: 8px;
  indicatorheight: 3px;
  tabbackgroundcolor: #ffffff;
  indicatorcolor: #dc1323;
  textselectcolor: #dc1323;
  textunselectcolor: #828282;
}

.tabbar /deep/ .van-tabs__content {
  overflow-y: auto;
  height: calc(100% - 89px);
}

.tabbar-item {
  flex: 1;
  /* width: 750px; */
  padding-bottom: 78px;
}

.method-list {
  position: absolute;
  top: 86px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f4f8f9;
  padding-top: 36px;
  z-index: 1;
}

.method-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f4f8f9;
}

.method-list-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 210px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 6px;
  margin: 12px;
  border-width: 1px;
  border-color: #e4e4e4;
  border-style: solid;
  background-color: #ffffff;
}

.method-list-title {
  width: 100%;
  height: 38px;
  margin-left: 20px;
  text-align: center;
  font-size: 26px;
  color: #242424;
  padding: 0;
  margin: 0;
  text-align: center;
  background-color: #fff;
}

.method-list-title /deep/ .van-notice-bar__wrap {
  justify-content: center;
}

.method-list-odds {
  padding-top: 5px;
  text-align: center;
  font-size: 22px;
  color: #888888;
}
</style>

<script>
import { getLotteryOdds } from '@/api/lottery'
import {
  each,
  count,
  runNum,
  methodCn,
  splitNum,
  rightExists,
  strExists,
  inArray
} from '@/utils/unique'
import { Tab, Tabs } from 'vant'
import RoomSendSscDwd from './components/room_send_ssc_dwd'
import RoomSendSscDxds from './components/room_send_ssc_dxds'
import RoomSendSscHszl from './components/room_send_ssc_hszl'
import RoomSendSscQszs from './components/room_send_ssc_qszs'
import RoomSendSscQszl from './components/room_send_ssc_qszl'
import RoomSendSscHszs from './components/room_send_ssc_hszs'
import RoomSendSscZh from './components/room_send_ssc_zh'
import RoomSendPk10Dwd from './components/room_send_pk10_dwd'
import RoomSendPk10Dxds from './components/room_send_pk10_dxds'
import RoomSendPk10Gyh from './components/room_send_pk10_gyh'
import RoomSendPk10Lh from './components/room_send_pk10_lh'
import RoomSendPcddDwd from './components/room_send_pcdd_dwd'
import RoomSendPcddDxds from './components/room_send_pcdd_dxds'
import RoomSendPcddBs from './components/room_send_pcdd_bs'
import RoomSendPcddBz from './components/room_send_pcdd_bz'
import RoomSendPcddZh from './components/room_send_pcdd_zh'
import RoomSendLhcTm from './components/room_send_lhc_tm'
import RoomSendLhcZm from './components/room_send_lhc_zm'
import RoomSendLhcTmdxds from './components/room_send_lhc_tmdxds'
import RoomSendLhcTmhsdxds from './components/room_send_lhc_tmhsdxds'
import RoomSendLhcTmwsdx from './components/room_send_lhc_tmwsdx'
import RoomSendLhcTmdxdsfh from './components/room_send_lhc_tmdxdsfh'
import RoomSendSscLh from './components/room_send_ssc_lh'
import RoomSendSscQedxds from './components/room_send_ssc_qedxds'
import RoomSendLhcZx from './components/room_send_lhc_zx'
import RoomSendLhcTx from './components/room_send_lhc_tx'
import RoomSendLhcSqz from './components/room_send_lhc_sqz'
import RoomSendLhcEqz from './components/room_send_lhc_eqz'
import RoomSendLhcTmsb from './components/room_send_lhc_tmsb'
import RoomSendX5Dwd from './components/room_send_x5_dwd'
import RoomSendX5Rx1z1 from './components/room_send_x5_rx1z1'
import RoomSendX5Rx2z2 from './components/room_send_x5_rx2z2'
import RoomSendX5Rx3z3 from './components/room_send_x5_rx3z3'
import RoomSendX5Rx4z4 from './components/room_send_x5_rx4z4'
import RoomSendX5Rx5z5 from './components/room_send_x5_rx5z5'
import RoomSendX5Rx6z5 from './components/room_send_x5_rx6z5'
import RoomSendX5Rx7z5 from './components/room_send_x5_rx7z5'
import RoomSendX5Rx8z5 from './components/room_send_x5_rx8z5'
import RoomSendX5Qszhix from './components/room_send_x5_qszhix'
import RoomSendX5Qszux from './components/room_send_x5_qszux'
import RoomSendX5Hszhix from './components/room_send_x5_hszhix'
import RoomSendX5Hszux from './components/room_send_x5_hszux'
import RoomSendX5Qezhix from './components/room_send_x5_qezhix'
import RoomSendX5Qezux from './components/room_send_x5_qezux'
import RoomSendX5Hezhix from './components/room_send_x5_hezhix'
import RoomSendX5Hezux from './components/room_send_x5_hezux'
import RoomSendSdDwd from './components/room_send_sd_dwd'
import RoomSendSdHedxds from './components/room_send_sd_hedxds'
import RoomSendSdZxzs from './components/room_send_sd_zxzs'
import RoomSendSdZxzl from './components/room_send_sd_zxzl'
import RoomSendSdQezhix from './components/room_send_sd_qezhix'
import RoomSendSdQezux from './components/room_send_sd_qezux'
import RoomSendSdHezhix from './components/room_send_sd_hezhix'
import RoomSendSdHezux from './components/room_send_sd_hezux'
import RoomSendK3Hz from './components/room_send_k3_hz'
import RoomSendK3Sth from './components/room_send_k3_sth'
import RoomSendK3Sbth from './components/room_send_k3_sbth'
import RoomSendK3Lhao from './components/room_send_k3_lhao'
import RoomSendK3Eth from './components/room_send_k3_eth'
import RoomSendK3Ebth from './components/room_send_k3_ebth'

export default {
  name: 'RoomSend',
  components: {
    Tab,
    Tabs,
    RoomSendK3Ebth,
    RoomSendK3Eth,
    RoomSendK3Lhao,
    RoomSendK3Sbth,
    RoomSendK3Sth,
    RoomSendK3Hz,
    RoomSendSdHezux,
    RoomSendSdHezhix,
    RoomSendSdQezux,
    RoomSendSdQezhix,
    RoomSendSdZxzl,
    RoomSendSdZxzs,
    RoomSendSdHedxds,
    RoomSendSdDwd,
    RoomSendX5Hezux,
    RoomSendX5Hezhix,
    RoomSendX5Qezux,
    RoomSendX5Qezhix,
    RoomSendX5Hszux,
    RoomSendX5Hszhix,
    RoomSendX5Qszux,
    RoomSendX5Qszhix,
    RoomSendX5Rx8z5,
    RoomSendX5Rx7z5,
    RoomSendX5Rx6z5,
    RoomSendX5Rx5z5,
    RoomSendX5Rx4z4,
    RoomSendX5Rx3z3,
    RoomSendX5Rx2z2,
    RoomSendX5Rx1z1,
    RoomSendX5Dwd,
    RoomSendLhcTmsb,
    RoomSendLhcEqz,
    RoomSendLhcSqz,
    RoomSendLhcTx,
    RoomSendLhcZx,
    RoomSendSscQedxds,
    RoomSendSscLh,
    RoomSendLhcTmdxdsfh,
    RoomSendLhcTmwsdx,
    RoomSendLhcTmhsdxds,
    RoomSendLhcTmdxds,
    RoomSendLhcZm,
    RoomSendLhcTm,
    RoomSendPcddZh,
    RoomSendPcddBz,
    RoomSendPcddBs,
    RoomSendPcddDxds,
    RoomSendPcddDwd,
    RoomSendPk10Lh,
    RoomSendPk10Gyh,
    RoomSendPk10Dxds,
    RoomSendPk10Dwd,
    RoomSendSscZh,
    RoomSendSscHszs,
    RoomSendSscQszl,
    RoomSendSscQszs,
    RoomSendSscHszl,
    RoomSendSscDxds,
    RoomSendSscDwd
  },
  props: {
    hall_methods: {
      type: Object
    },
    type: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      title: this.$t('common.loadingTwo'),

      oddsTrees: [],

      oddsLists: [],

      selectLists: [],

      selectCodes: {},

      showMethod: false,
      active: 0
    }
  },

  mounted() {
    setTimeout(() => {
      this.getLotteryOddsInfo()
    }, 10)
  },

  methods: {
    methodCnEvent(val) {
      return methodCn(val)
    },
    splitNumEvent(val, num) {
      return splitNum(val, num)
    },
    async getLotteryOddsInfo() {
      this.$toast.loading({ forbidClick: true, loadingType: 'spinner' })
      const {
        data: { ret, data }
      } = await getLotteryOdds({
        type: this.type,
        version: '2.0'
      })
      if (ret === 1) {
        let newLists = []
        if (count(data.odds) > 0) {
          newLists = []
          each(data.odds, (index, item) => {
            if (runNum(item.odds) > 0) {
              if (
                typeof this.hall_methods === 'object' &&
                Object.keys(this.hall_methods).length
              ) {
                if (inArray(item.method, this.hall_methods)) {
                  newLists.push(item)
                }
              } else {
                newLists.push(item)
              }
            }
          })
          this.oddsLists = newLists
        }
        if (count(data.oddtrees) > 0) {
          newLists = []
          each(data.oddtrees, (index, item) => {
            if (
              typeof this.hall_methods === 'object' &&
              Object.keys(this.hall_methods).length
            ) {
              if (inArray(item.method, this.hall_methods)) {
                newLists.push(item)
              }
            } else {
              newLists.push(item)
            }
          })
          this.oddsTrees = newLists
        }
        // this.pageSelected(0)
        this.handleTabsChange(0)
      } else if (ret === -1) {
        this.$toast(this.$t('home.unableToParameters'))
      } else {
        this.$toast(ret.msg)
      }
    },

    /**
     * 关闭
     */
    clickClose() {
      this.selectCodes = {}
      this.$emit('clickClose')
    },

    /**
     * 选择玩法
     */
    clickSelect() {
      this.showMethod = !this.showMethod
    },

    /**
     * 选择玩法
     */
    methodClick(key1, key2) {
      this.showMethod = false
      this.handleTabsChange(key1 * 3 + key2)
      // this.$refs.myTabbar.setCurrentItem('tabName_' + (key1 * 3 + key2))
    },

    /**
     * 确定
     */
    clickSend() {
      let odds = ''
      each(this.oddsLists, (index, item) => {
        odds += odds ? '、' : ''
        odds += methodCn(item.method) + '（' + item.odds + '）'
      })
      //
      let number = 0
      let numberInfo = ''
      let oneOrderMoney = 0
      const oneMethodMoney = {}
      const selectCodeString = {}
      const codeStringType = rightExists(this.type, 'ssc') ? this.type : ''
      each(this.selectCodes, (method, item) => {
        const count = this.codeNumber(method, item)
        if (count > 0) {
          number += count
          numberInfo += numberInfo ? '、' : ''
          numberInfo += methodCn(method) + ':' + count + this.$t('home.note')
          oneOrderMoney += this.codeOneOrderMoney(method, item)
          oneMethodMoney[method] = this.codeOneOrderMoney(method, item)
          selectCodeString[method] = this.codeString(
            method,
            item,
            codeStringType
          )
        }
      })
      //
      if (number <= 0) {
        this.$toast(this.$t('home.cannotGetParameter'))
        return
      }
      //
      this.$emit('clickSend', {
        odds: odds, // 赔率
        number: number, // cược số
        numberInfo: numberInfo, // 注数说明
        oneOrderMoney: oneOrderMoney, // 1元最高中奖金额
        oneMethodMoney: oneMethodMoney, // 1元最高中奖金额（组）
        selectCodeString: selectCodeString // 选择的号码组
      })
    },

    /**
     * tab选择
     * @param data
     */
    pageSelected(data) {
      const lists = this.oddsTrees.length > 0 ? this.oddsTrees : this.oddsLists
      const info = typeof data === 'number' ? lists[data] : lists[data.position]
      let title = methodCn(info.method)
      const len = title.length
      if (len > 5) {
        title = title.substring(0, 4) + '...'
      }
      const odds = info.odds ? '（' + info.odds + '）' : ''
      this.title = count(info) > 0 ? title + odds : ''
    },
    handleTabsChange(data) {
      this.active = data
      const lists = this.oddsTrees.length > 0 ? this.oddsTrees : this.oddsLists
      const info = typeof data === 'number' ? lists[data] : lists[data.position]
      let title = methodCn(info.method)
      const len = title.length
      if (len > 5) {
        title = title.substring(0, 4) + '...'
      }
      const odds = info.odds ? '（' + info.odds + '）' : ''
      this.title = count(info) > 0 ? title + odds : ''
    },

    /**
     * 号码组是否符合要求
     * @param method
     * @param codes
     * @returns {boolean}
     */
    codeMeet(method, codes) {
      return this.codeString(method, codes) !== ''
    },

    /**
     * 号码组转成字符串
     * @param method
     * @param codes
     * @param type
     * @returns {string}
     */
    codeString(method, codes, type) {
      let space = ''
      if (type === 'hk6hc' || type === 'js6hc') {
        space = ' '
      } else if (rightExists(type, 'ssc') && method === 'lh') {
        space = ','
      }
      //
      let string = ''
      for (let i = 0; i < codes.length; i++) {
        string += i > 0 ? ',' : ''
        for (let j = 0; j < codes[i].length; j++) {
          if (
            typeof codes[i][j] !== 'undefined' &&
            codes[i][j] !== '' &&
            codes[i][j] !== null
          ) {
            if (string !== '') string += space
            string += codes[i][j]
          }
        }
      }
      return this.codeLengthCheck(method, string) ? string : ''
    },

    /**
     * object转成字符串
     * @param object
     * @returns {string}
     */
    objectString(object) {
      let string = ''
      if (typeof object === 'object' && object !== null) {
        each(object, (index, item) => {
          if (typeof item !== 'undefined' && item !== '' && item !== null) {
            if (typeof item === 'object') {
              string += this.objectString(item)
            } else {
              string += item
            }
          }
        })
      }
      return string
    },

    /**
     * 号码组获取注数
     * @param method
     * @param codes
     * @returns {number}
     */
    codeNumber(method, codes) {
      let newCount = 0
      if (codes.length) {
        codes.forEach((_) => {
          _.forEach((e) => {
            if (e || e == 0) {
              newCount++
            }
          })
        })
      }
      let nn
      const len = this.codeString(method, codes).replace(/\,/g, '').length
      let number = 0
      switch (this.type) {
        case 'cqssc':
        case 'xjssc':
        case 'tjssc':
        case 'sfssc':
        case 'txffc':
          switch (method) {
            case 'dwd':
            case 'dxds':
            case 'zh':
              // number = len
              number = newCount
              break

            case 'hszs':
            case 'qszs':
              number = len * (len - 1)
              break

            case 'hszl':
            case 'qszl':
              nn = len - 2
              number = (nn * (nn + 1) * (nn + 2)) / 6
              break

            case 'lh':
              number = len / 4
              break

            case 'qedxds':
              number =
                this.objectString(codes[0]).length *
                this.objectString(codes[1]).length
              break
          }
          break

        case 'bjsc':
        case 'xyft':
        case 'sfpk10':
          switch (method) {
            case 'dwd':
              number = len / 2
              break

            case 'dxds':
            case 'gyh':
            case 'lh':
              number = len
              break
          }
          break

        case 'pcdd':
        case 'jnd28':
        case 'xy28':
          switch (method) {
            case 'dwd':
            case 'dxds':
            case 'zh':
            case 'bs':
              number = len
              break

            case 'bz':
              number = len / 2
              break
          }
          break

        case 'hk6hc':
        case 'js6hc':
          switch (method) {
            case 'tm':
            case 'zm':
            case 'tmdxdsfh':
              number = len / 2
              break

            case 'tmdxds':
            case 'tmhsdxds':
            case 'tmwsdx':
            case 'tx':
            case 'zx':
            case 'tmsb':
              number = len
              break

            case 'sqz':
              nn = len / 2 - 2
              number = (nn * (nn + 1) * (nn + 2)) / 6
              break

            case 'eqz':
              nn = len / 2 - 1
              number = (nn * (nn + 1)) / 2
              break
          }
          break

        case 'jx11x5':
        case 'sd11x5':
        case 'sf11x5':
          switch (method) {
            case 'dwd':
            case 'rx1z1':
              number = len / 2
              break

            case 'rx2z2':
              nn = len / 2 - 1
              number = (nn * (nn + 1)) / 2
              break

            case 'rx3z3':
              nn = len / 2 - 2
              number = (nn * (nn + 1) * (nn + 2)) / 6
              break

            case 'rx4z4':
              nn = len / 2 - 3
              number = (nn * (nn + 1) * (nn + 2) * (nn + 3)) / 24
              break

            case 'rx5z5':
              nn = len / 2 - 4
              number = (nn * (nn + 1) * (nn + 2) * (nn + 3) * (nn + 4)) / 120
              break

            case 'rx6z5':
              nn = len / 2 - 5
              number =
                (nn * (nn + 1) * (nn + 2) * (nn + 3) * (nn + 4) * (nn + 5)) /
                720
              break

            case 'rx7z5':
              nn = len / 2 - 6
              number =
                (nn *
                  (nn + 1) *
                  (nn + 2) *
                  (nn + 3) *
                  (nn + 4) *
                  (nn + 5) *
                  (nn + 6)) /
                5040
              break

            case 'rx8z5':
              nn = len / 2 - 7
              number =
                (nn *
                  (nn + 1) *
                  (nn + 2) *
                  (nn + 3) *
                  (nn + 4) *
                  (nn + 5) *
                  (nn + 6) *
                  (nn + 7)) /
                40320
              break

            case 'qszhix':
            case 'hszhix':
              number += this.get_11x5_3ZhixNum(codes)
              break

            case 'qszux':
            case 'hszux':
              nn = len / 2 - 2
              number = (nn * (nn + 1) * (nn + 2)) / 6
              break

            case 'qezhix':
            case 'hezhix':
              number += this.get_11x5_2ZhixNum(codes)
              break

            case 'qezux':
            case 'hezux':
              nn = len / 2 - 1
              number = (nn * (nn + 1)) / 2
              break
          }
          break

        case 'fc3d':
        case 'sf3d':
          switch (method) {
            case 'dwd':
              number = len
              break

            case 'hedxds':
              number =
                this.objectString(codes[0]).length *
                this.objectString(codes[1]).length
              break

            case 'zxzs':
              number = len * (len - 1)
              break

            case 'zxzl':
              nn = len - 2
              number = (nn * (nn + 1) * (nn + 2)) / 6
              break

            case 'qezhix':
            case 'hezhix':
              number += this.get_3d_2ZhixNum(codes)
              break

            case 'qezux':
            case 'hezux':
              nn = len - 1
              number = (nn * (nn + 1)) / 2
              break
          }
          break

        case 'ffk3':
          switch (method) {
            case 'hz-hz-dxds':
              number = len
              break

            case 'hz-hz-3':
            case 'hz-hz-4':
            case 'hz-hz-5':
            case 'hz-hz-6':
            case 'hz-hz-7':
            case 'hz-hz-8':
            case 'hz-hz-9':
            case 'hz-hz-10':
            case 'hz-hz-11':
            case 'hz-hz-12':
            case 'hz-hz-13':
            case 'hz-hz-14':
            case 'hz-hz-15':
            case 'hz-hz-16':
            case 'hz-hz-17':
            case 'hz-hz-18':
              number = len ? 1 : 0
              break

            case 'sth-tx-tx':
              number = len ? 1 : 0
              break

            case 'sth-dx-dx':
              number = len / 3
              break

            case 'sbth-bz-bz':
              nn = len - 2
              number = (nn * (nn + 1) * (nn + 2)) / 6
              break

            case 'lhao-slh-slh':
              number = len ? 1 : 0
              break

            case 'eth-fx-fx':
              number = len / 2
              break

            case 'eth-dx-dx':
              number =
                (this.objectString(codes[0]).length / 2) *
                this.objectString(codes[1]).length
              break

            case 'ebth-bz-bz':
              nn = len - 1
              number = (nn * (nn + 1)) / 2
              break
          }
          break
      }
      return number
    },

    /**
     * 号码组获取1元最高中奖金额
     * @param method
     * @param codes
     * @returns {number}
     */
    codeOneOrderMoney(method, codes) {
      let nn
      let money = 0
      const string = this.codeString(method, codes)
      const strings = string.split(',')
      if (count(string) === 0) {
        return 0
      }
      //
      switch (this.type) {
        case 'cqssc':
        case 'xjssc':
        case 'tjssc':
        case 'sfssc':
        case 'txffc':
          switch (method) {
            case 'dwd':
              each(strings, (i, v) => {
                if (count(v) > 0) money++
              })
              break

            case 'dxds':
              each(strings, (i, v) => {
                if (strExists(v, this.$t('common.resources')) || strExists(v, this.$t('common.faint'))) money++
                if (strExists(v, this.$t('common.odd')) || strExists(v, this.$t('common.related'))) money++
              })
              break

            case 'zh':
              each(strings, (i, v) => {
                if (strExists(v, this.$t('common.resources')) || strExists(v, this.$t('common.faint'))) money++
                if (strExists(v, this.$t('common.odd')) || strExists(v, this.$t('common.related'))) money++
              })
              break

            case 'hszs':
            case 'qszs':
            case 'hszl':
            case 'qszl':
              money++
              break

            case 'lh':
              each(strings, (i, v) => {
                if (
                  strExists(v, this.$t('home.forPurposeKeepingPerson')) ||
                  strExists(v, this.$t('home.inThisCaseYou'))
                ) {
                  money++
                }
                if (
                  strExists(v, this.$t('home.thePersonIs')) ||
                  strExists(v, this.$t('home.takeCareYourLife'))
                ) {
                  money++
                }
                if (
                  strExists(v, this.$t('home.keepYouWatchIt')) ||
                  strExists(v, this.$t('home.keepWatchIt'))
                ) {
                  money++
                }
                if (
                  strExists(v, this.$t('home.tenThousandVsPlaces')) ||
                  strExists(v, this.$t('home.placesVsTenThousand'))
                ) {
                  money++
                }
                if (
                  strExists(v, this.$t('home.inFutureYourself')) ||
                  strExists(v, this.$t('home.keepYourKeepYourPeeled'))
                ) {
                  money++
                }
                if (
                  strExists(v, this.$t('home.theFutureMore')) ||
                  strExists(v, this.$t('home.dealWithTheSituation'))
                ) {
                  money++
                }
                if (
                  strExists(v, this.$t('home.thousandVsPlaces')) ||
                  strExists(v, this.$t('home.locationVsThousands'))
                ) {
                  money++
                }
                if (
                  strExists(v, this.$t('home.makeYourBalance')) ||
                  strExists(v, this.$t('home.keepLearnMore'))
                ) {
                  money++
                }
                if (
                  strExists(v, this.$t('home.hundredsOfPlaces')) ||
                  strExists(v, this.$t('home.locationVsHundred'))
                ) {
                  money++
                }
                if (
                  strExists(v, this.$t('home.tenVsPlaces')) ||
                  strExists(v, this.$t('home.LocationVsTen'))
                ) {
                  money++
                }
              })
              break

            case 'qedxds':
              let m0 = 0
              let m1 = 0
              if (
                strExists(strings[0], this.$t('common.resources')) ||
                strExists(strings[0], this.$t('common.faint'))
              ) {
                m0++
              }
              if (strExists(strings[0], this.$t('common.odd')) || strExists(strings[0], this.$t('common.related'))) {
                m0++
              }
              if (
                strExists(strings[1], this.$t('common.resources')) ||
                strExists(strings[1], this.$t('common.faint'))
              ) {
                m1++
              }
              if (strExists(strings[1], this.$t('common.odd')) || strExists(strings[1], this.$t('common.related'))) {
                m1++
              }
              money += m0 * m1
              break
          }
          break

        case 'bjsc':
        case 'xyft':
        case 'sfpk10':
          switch (method) {
            case 'dwd':
            case 'lh':
              each(strings, (i, v) => {
                if (count(v) > 0) money++
              })
              break

            case 'dxds':
            case 'gyh':
              each(strings, (i, v) => {
                if (strExists(v, this.$t('common.resources')) || strExists(v, this.$t('common.faint'))) money++
                if (strExists(v, this.$t('common.odd')) || strExists(v, this.$t('common.related'))) money++
              })
              break
          }
          break

        case 'pcdd':
        case 'jnd28':
        case 'xy28':
          switch (method) {
            case 'dwd':
              each(strings, (i, v) => {
                if (count(v) > 0) money++
              })
              break

            case 'dxds':
              each(strings, (i, v) => {
                if (strExists(v, this.$t('common.resources')) || strExists(v, this.$t('common.faint'))) money++
                if (strExists(v, this.$t('common.odd')) || strExists(v, this.$t('common.related'))) money++
              })
              break

            case 'zh':
              each(strings, (i, v) => {
                if (strExists(v, this.$t('common.resources')) || strExists(v, this.$t('common.faint'))) money++
                if (strExists(v, this.$t('common.odd')) || strExists(v, this.$t('common.related'))) money++
              })
              break

            case 'bs':
            case 'bz':
              money++
              break
          }
          break

        case 'hk6hc':
        case 'js6hc':
          switch (method) {
            case 'tm':
              money++
              break

            case 'zm':
              money += Math.min(6, strings.length / 2)
              break

            case 'tmdxds':
            case 'tmhsdxds':
              each(strings, (i, v) => {
                if (strExists(v, this.$t('common.resources')) || strExists(v, this.$t('common.faint'))) money++
                if (strExists(v, this.$t('common.odd')) || strExists(v, this.$t('common.related'))) money++
              })
              break

            case 'tmwsdx':
              each(strings, (i, v) => {
                if (strExists(v, this.$t('common.resources')) || strExists(v, this.$t('common.faint'))) money++
              })
              break

            case 'tmdxdsfh':
              money++
              break

            case 'tx':
              money++
              break

            case 'zx':
              money += Math.min(string.length, 6)
              break

            case 'sqz':
              nn = Math.min(string.length, 12) / 2 - 2
              money += (nn * (nn + 1) * (nn + 2)) / 6
              break

            case 'eqz':
              nn = Math.min(string.length, 12) / 2 - 1
              money += (nn * (nn + 1)) / 2
              break

            case 'tmsb':
              money++
              break
          }
          break

        case 'jx11x5':
        case 'sd11x5':
        case 'sf11x5':
          switch (method) {
            case 'dwd':
              each(strings, (i, v) => {
                if (count(v) > 0) money++
              })
              break

            case 'rx1z1':
              nn = Math.min(string.length, 10) / 2
              money += nn
              break

            case 'rx2z2':
              nn = Math.min(string.length, 10) / 2 - 1
              money += (nn * (nn + 1)) / 2
              break

            case 'rx3z3':
              nn = Math.min(string.length, 10) / 2 - 2
              money += (nn * (nn + 1) * (nn + 2)) / 6
              break

            case 'rx4z4':
              nn = Math.min(string.length, 10) / 2 - 3
              money += (nn * (nn + 1) * (nn + 2) * (nn + 3)) / 24
              break

            case 'rx5z5':
            case 'rx6z5':
            case 'rx7z5':
            case 'rx8z5':
              money++
              break

            case 'qszhix':
            case 'hszhix':
              money++
              break

            case 'qszux':
            case 'hszux':
              money++
              break

            case 'qezhix':
            case 'hezhix':
              money++
              break

            case 'qezux':
            case 'hezux':
              money++
              break
          }
          break

        case 'fc3d':
        case 'sf3d':
          switch (method) {
            case 'dwd':
              each(strings, (i, v) => {
                if (count(v) > 0) money++
              })
              break

            case 'hedxds':
              let m0 = 0
              let m1 = 0
              if (
                strExists(strings[0], this.$t('common.resources')) ||
                strExists(strings[0], this.$t('common.faint'))
              ) {
                m0++
              }
              if (strExists(strings[0], this.$t('common.odd')) || strExists(strings[0], this.$t('common.related'))) {
                m0++
              }
              if (
                strExists(strings[1], this.$t('common.resources')) ||
                strExists(strings[1], this.$t('common.faint'))
              ) {
                m1++
              }
              if (strExists(strings[1], this.$t('common.odd')) || strExists(strings[1], this.$t('common.related'))) {
                m1++
              }
              money += m0 * m1
              break

            case 'zxzs':
              money++
              break

            case 'zxzl':
              money++
              break

            case 'qezhix':
            case 'hezhix':
              money++
              break

            case 'qezux':
            case 'hezux':
              money++
              break
          }
          break

        case 'ffk3':
          switch (method) {
            case 'hz-hz-dxds':
              each(strings, (i, v) => {
                if (strExists(v, this.$t('common.resources')) || strExists(v, this.$t('common.faint'))) money++
                if (strExists(v, this.$t('common.odd')) || strExists(v, this.$t('common.related'))) money++
              })
              break

            case 'hz-hz-3':
            case 'hz-hz-4':
            case 'hz-hz-5':
            case 'hz-hz-6':
            case 'hz-hz-7':
            case 'hz-hz-8':
            case 'hz-hz-9':
            case 'hz-hz-10':
            case 'hz-hz-11':
            case 'hz-hz-12':
            case 'hz-hz-13':
            case 'hz-hz-14':
            case 'hz-hz-15':
            case 'hz-hz-16':
            case 'hz-hz-17':
            case 'hz-hz-18':
              money++
              break

            case 'sth-tx-tx':
              money++
              break

            case 'sth-dx-dx':
              money++
              break

            case 'sbth-bz-bz':
              money++
              break

            case 'lhao-slh-slh':
              money++
              break

            case 'eth-fx-fx':
              money++
              break

            case 'eth-dx-dx':
              money++
              break

            case 'ebth-bz-bz':
              money++
              break
          }
          break
      }
      each(this.oddsLists, (index, item) => {
        if (item.method === method) {
          money *= item.odds
        }
      })
      return money
    },

    /**
     * 号码字符串长度检测
     * @param method
     * @param string
     * @returns {boolean}
     */
    codeLengthCheck(method, string) {
      let nn
      const len = string.replace(/\,/g, '').length
      let ask = -1
      switch (this.type) {
        case 'cqssc':
        case 'xjssc':
        case 'tjssc':
        case 'sfssc':
        case 'txffc':
          switch (method) {
            case 'dwd':
            case 'dxds':
            case 'zh':
              ask = 1
              break
            case 'hszs':
            case 'qszs':
              ask = 2
              break
            case 'hszl':
            case 'qszl':
              ask = 3
              break
            case 'lh':
              ask = 4
              break
            case 'qedxds':
              const strings = string.split(',')
              return count(strings[0]) * count(strings[1]) > 0
          }
          break

        case 'bjsc':
        case 'xyft':
        case 'sfpk10':
          switch (method) {
            case 'dwd':
            case 'dxds':
            case 'gyh':
            case 'lh':
              ask = 1
              break
          }
          break

        case 'pcdd':
        case 'jnd28':
        case 'xy28':
          switch (method) {
            case 'dwd':
            case 'dxds':
            case 'zh':
            case 'bs':
              ask = 1
              break

            case 'bz':
              ask = 2
              break
          }
          break

        case 'hk6hc':
        case 'js6hc':
          switch (method) {
            case 'tm':
            case 'zm':
            case 'tmdxdsfh':
              ask = 2
              break

            case 'tmdxds':
            case 'tmhsdxds':
            case 'tmwsdx':
            case 'tx':
            case 'zx':
            case 'tmsb':
              ask = 1
              break

            case 'sqz':
              ask = 6
              break

            case 'eqz':
              ask = 4
              break
          }
          break

        case 'jx11x5':
        case 'sd11x5':
        case 'sf11x5':
          switch (method) {
            case 'dwd':
              ask = 1
              break

            case 'rx1z1':
              ask = 1
              break

            case 'rx2z2':
              ask = 4
              break

            case 'rx3z3':
              ask = 6
              break

            case 'rx4z4':
              ask = 8
              break

            case 'rx5z5':
              ask = 10
              break

            case 'rx6z5':
              ask = 12
              break

            case 'rx7z5':
              ask = 14
              break

            case 'rx8z5':
              ask = 16
              break

            case 'qszhix':
            case 'hszhix':
              nn = []
              each(string.split(','), (k, v) => {
                nn.push(this.strCut2Array(v, 2))
              })
              return this.get_11x5_3ZhixNum(nn) > 0

            case 'qszux':
            case 'hszux':
              ask = 6
              break

            case 'qezhix':
            case 'hezhix':
              nn = []
              each(string.split(','), (k, v) => {
                nn.push(this.strCut2Array(v, 2))
              })
              return this.get_11x5_2ZhixNum(nn) > 0

            case 'qezux':
            case 'hezux':
              ask = 4
              break
          }
          break

        case 'fc3d':
        case 'sf3d':
          switch (method) {
            case 'dwd':
              ask = 1
              break

            case 'hedxds':
              const strings = string.split(',')
              return count(strings[0]) * count(strings[1]) > 0

            case 'zxzs':
              ask = 2
              break

            case 'zxzl':
              ask = 3
              break

            case 'qezhix':
            case 'hezhix':
              nn = []
              each(string.split(','), (k, v) => {
                nn.push(this.strCut2Array(v, 1))
              })
              return this.get_3d_2ZhixNum(nn) > 0

            case 'qezux':
            case 'hezux':
              ask = 2
              break
          }
          break

        case 'ffk3':
          switch (method) {
            case 'hz-hz-dxds':
              ask = 1
              break

            case 'hz-hz-3':
            case 'hz-hz-4':
            case 'hz-hz-5':
            case 'hz-hz-6':
            case 'hz-hz-7':
            case 'hz-hz-8':
            case 'hz-hz-9':
            case 'hz-hz-10':
            case 'hz-hz-11':
            case 'hz-hz-12':
            case 'hz-hz-13':
            case 'hz-hz-14':
            case 'hz-hz-15':
            case 'hz-hz-16':
            case 'hz-hz-17':
            case 'hz-hz-18':
              ask = 1
              break

            case 'sth-tx-tx':
              ask = 1
              break

            case 'sth-dx-dx':
              ask = 1
              break

            case 'sbth-bz-bz':
              ask = 3
              break

            case 'lhao-slh-slh':
              ask = 1
              break

            case 'eth-fx-fx':
              ask = 1
              break

            case 'eth-dx-dx':
              nn = []
              each(string.split(','), (k, v) => {
                nn.push(this.strCut2Array(v, k === 0 ? 2 : 1))
              })
              return this.get_k3_eth_dx(nn) > 0

            case 'ebth-bz-bz':
              ask = 2
              break
          }
          break
      }
      return !(ask === -1 || len < ask)
    },

    /**
     * 清除选择
     * @param method
     */
    codeClean(method) {
      this.selectCodes[method] = ''
    },

    /**
     * 按多长间隔截取字符串
     * @param str
     * @param n
     * @returns {Array}
     */
    strCut2Array(str, n) {
      const strArr = []
      for (let i = 0, l = str.length; i < l / n; i++) {
        const a = str.slice(n * i, n * (i + 1))
        strArr.push(a)
      }
      return strArr
    },

    /**
     * 11x5 3直选
     * @param codes
     * @returns {number}
     */
    get_11x5_3ZhixNum(codes) {
      let number = 0
      each(codes[0], (key0, val0) => {
        if (runNum(val0) < 1 || runNum(val0) > 11) return true
        each(codes[1], (key1, val1) => {
          if (runNum(val1) < 1 || runNum(val1) > 11) return true
          each(codes[2], (key2, val2) => {
            if (runNum(val2) < 1 || runNum(val2) > 11) return true
            //
            if (val0 !== val1 && val0 !== val2 && val1 !== val2) {
              number++
            }
          })
        })
      })
      return number
    },

    /**
     * 11x5 2直选
     * @param codes
     * @returns {number}
     */
    get_11x5_2ZhixNum(codes) {
      let number = 0
      each(codes[0], (key0, val0) => {
        if (runNum(val0) < 1 || runNum(val0) > 11) return true
        each(codes[1], (key1, val1) => {
          if (runNum(val1) < 1 || runNum(val1) > 11) return true
          //
          if (val0 !== val1) {
            number++
          }
        })
      })
      return number
    },

    /**
     * 3D 2直选
     * @param codes
     * @returns {number}
     */
    get_3d_2ZhixNum(codes) {
      let number = 0
      each(codes[0], (key0, val0) => {
        if (val0 === '' || runNum(val0) < 0 || runNum(val0) > 9) return true
        each(codes[1], (key1, val1) => {
          if (val0 === '' || runNum(val1) < 0 || runNum(val1) > 9) return true
          //
          number++
        })
      })
      return number
    },

    /**
     * 快三 二同号-một选
     * @param codes
     * @returns {number}
     */
    get_k3_eth_dx(codes) {
      let number = 0
      each(codes[0], (key0, val0) => {
        if (val0 === '' || !inArray(runNum(val0), [11, 22, 33, 44, 55, 66])) {
          return true
        }
        each(codes[1], (key1, val1) => {
          if (val0 === '' || runNum(val1) < 1 || runNum(val1) > 6) return true
          //
          number++
        })
      })
      return number
    }
  }
}
</script>
