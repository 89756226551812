<template>
  <div class="list" pullTips="false">
    <div v-for="pos in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]" :key="pos">
      <div class="item">
        <span class="item-title">{{ pos | unit }}</span>
        <div class="item-line"></div>
        <div class="item-right">
          <div class="item-row">
            <span v-for="(code, num) in ['01', '02', '03', '04', '05']" :key="num" @click="select(pos, num, code)"
              :class="[codes[pos][num] === code ? 'item-action' : 'item-code']">{{ code }}
            </span>
          </div>
          <div class="item-space"></div>
          <div class="item-row">
            <span v-for="(code, num) in ['06', '07', '08', '09', '10']" :key="num" @click="select(pos, num + 5, code)"
              :class="[
                codes[pos][num + 5] === code ? 'item-action' : 'item-code',
              ]">{{ code }}
            </span>
          </div>
        </div>
      </div>
      <div class="line" v-if="pos < 9"></div>
    </div>
  </div>
</template>

<style scoped>
.list {
  flex: 1;
}

.item {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 36px;
  padding-right: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f8fb;
}

.line {
  flex: 1;
  height: 1px;
  background-color: #e4e4e4;
}

.item-title {
  font-size: 28px;
  color: #999999;
}

.item-line {
  margin-left: 36px;
  margin-right: 36px;
  width: 1px;
  height: 60px;
  background-color: #e4e4e4;
}

.item-space {
  flex: 1;
  height: 16px;
}

.item-right {
  flex: 1;
}

.item-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.item-code,
.item-action {
  width: 76px;
  height: 76px;
  line-height: 76px;
  border-radius: 38px;
  font-size: 30px;
  text-align: center;
  color: #dc1323;
  background-color: #ffffff;
}

.item-action {
  color: #ffffff;
  background-color: #dc1323;
}
</style>

<script>
import { Dialog } from 'vant'
import { runNum } from '@/utils/unique'

export default {
  name: 'RoomSendPk10Dwd',

  props: {
    value: {
      // type: Object,
      // default: [],
    },
    maxnum: {
      default: 0,
    },
  },

  computed: {
    unit(pos) {
      // this.$t('home.prize10'),
      return [
        this.$t('home.champion'),
        this.$t('home.runnerUp'),
        this.$t('home.prize3'),
        this.$t('home.prize4'),
        this.$t('home.prize5'),
        this.$t('home.prize6'),
        this.$t('home.prize7'),
        this.$t('home.prize8'),
        this.$t('home.prize9'),
        this.$t('home.prize10'),
      ][pos]
    },
  },

  data() {
    return {
      codes: [[], [], [], [], [], [], [], [], [], []],
    }
  },

  mounted() { },

  watch: {
    value(val) {
      if (val === '') {
        const newValue = []
        for (let i = 0; i < this.codes.length; i++) {
          newValue.push([])
        }
        this.codes = newValue
        this.$emit('input', this.codes)
      }
    },
  },

  methods: {
    select(pos, num, code) {
      // 限制码数
      const maxNum = runNum(this.maxnum)
      if (maxNum > 0 && this.codes[pos][num] !== code) {
        let tmpNum = 0
        this.codes[pos].forEach((val) => {
          if (val != '') {
            tmpNum++
          }
        })
        if (tmpNum >= maxNum) {
          Dialog.alert({
            title: this.$t('home.advice'),
            message: this.$t('home.eachmaximumDigits', { maxNum: maxNum }),
            // message: '每组号码限制最大选择' + maxNum + '位！',
          })
          return
        }
      }
      //
      this.$set(this.codes[pos], num, this.codes[pos][num] !== code ? code : '')
      this.$emit('input', this.codes)
    },
  },
}
</script>
